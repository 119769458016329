import React from "react";
import Header from "../../Components/Header/Header";
import Service from "./service/Service";
import DescriptionIcon from "@material-ui/icons/Description";
import s from './Services.module.css'
import Admin from "../../Components/AdminArea/Admin";

export default function Services() {
  return (
    <div>
      <Header />
      <div>
      <div className={s.title}>
          <DescriptionIcon color="primary" style={{ fontSize: "36px" }} />
          <h1>Services</h1>
        </div>
        {localStorage.getItem("token") ? <Admin type={'service'}/> :null}
      </div>
      <Service />
    </div>
  );
}
