import React from "react";
import Header from "../../Components/Header/Header";
import OneNews from "./oneNews/OneNews";
import s from "./News.module.css";
import DescriptionIcon from "@material-ui/icons/Description";
import Admin from "../../Components/AdminArea/Admin";

export default function News() {
  return (
    <div>
      <Header />
      <div>
        <div className={s.title}>
          <DescriptionIcon color="primary" style={{ fontSize: "36px" }} />
          <h1>News</h1>
        </div>
        {localStorage.token ? <Admin type={"news"} /> : null}
      </div>
      <OneNews />
    </div>
  );
}
