import { BrowserRouter, Route } from "react-router-dom";
import ContactUs from "./HightComponents/ContactUs/ContactUs";
import Home from "./HightComponents/Home/Home";
import News from "./HightComponents/News/News";
import Product from "./HightComponents/Product/Product";
import Services from "./HightComponents/Services/Services";
import SubBranch from "./HightComponents/Product/subBranch/SubBranch";
import OneServicePage from "./HightComponents/forOnePage/OneServicePage/OneServicePage";
import OneNewsPage from "./HightComponents/forOnePage/oneNewsPage/OneNewsPage";
import OneProduct from "./HightComponents/forOnePage/OneProduct/OneProduct";
import NewProducts from "./HightComponents/NewProducts/NewProducts";
import LatestNews from "./HightComponents/LatestNews/LatestNews";
import Partners from "./HightComponents/Partners/Partners";
function App() {
  return (
    <BrowserRouter>
      <Route exact path="/" component={Home} />
      <Route exact path="/product" component={Product} />
      <Route exact path="/news" component={News} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/contact" component={ContactUs} />
      <Route exact path="/subproduct/:id" component={SubBranch} />
      <Route exact path="/oneservicepage" component={OneServicePage} />
      <Route exact path="/onenewspage" component={OneNewsPage} />
      <Route exact path="/OneProduct/:id" component={OneProduct} />
      <Route exact path="/new-products" component={NewProducts} />
      <Route exact path="/latest-news" component={LatestNews} />
      <Route exact path="/partners" component={Partners} />
    </BrowserRouter>
  );
}

export default App;
