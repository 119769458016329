import React from "react";
import s from "./Nav.module.css";
import comp1 from "../../../imgs/comp1.jpg";
import comp2 from "../../../imgs/comp2.jpg";
import comp3 from "../../../imgs/comp3.jpg";
import { Link } from "react-router-dom";

const info = [
  { img: comp1, text: "NEW PRODUCT", to:'/new-products' },
  { img: comp2, text: "LATEST NEWS", to:'/latest-news' },
  { img: comp3, text: "OUR PARTNERS", to:'/partners' },
];
const compon = info.map((c) => (
  <Link className={s.dv} key={JSON.stringify(c.text)+Math.random()*1500} to={c.to}>
    <img className={s.img} alt="child" src={c.img} />
    <div className={s.forBtn}>
      <button> {c.text}</button>
    </div>
  </Link>
));

export default function Nav() {
  return <div className={s.nav}>{compon}</div>;
}
