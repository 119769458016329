const translations = {
  home: { uk: "HOME", am: "Գլխավոր" },
  products: { uk: "PRODUCTS", am: "Ապրանքներ" },
  news: { uk: "NEWS", am: "Նորություններ" },
  services: { uk: "SERVICES", am: "Ծառայություններ" },
  contact: { uk: "CONTACT US", am: "Կապ մեզ հետ" },
  about: {uk:'about us',am:'մեր մասին'},
  aboutText:{
      uk:"“Optolaz LLC”  imports and sales medical devices and laboratory materials. We are a representative of Bio Optica/ Italy/, Volk/ USA/, AJL / Spain / in Armenia Our company provides warranty and post-warranty service too. Our team of experienced specialists is ready to perform a task of any complexity. Leading medical centers and laboratories  in Armenia cooperate with us.",
      am:"«Օպտոլազ» ՍՊԸ-ն իրականացնում է առաջատար բժշկական սարքավորումների և լաբորատոր նյութերի ներկմամբ և վաճառքով: Մենք ներկայացնում ենք Bio Optica /Իտալիա/, Volk /ԱՄՆ/, AJL /Իսպանիա/ ընկերությունների ներկայացուցիչը Հայաստանում: Ընկերությունը իրականացնում է նաև երաշխիքային և ետերաշխիքային սպասարկում: Մեր փորձառու մասնագետների թիմը պատրաստ է իրականացնել ցանկացած բարդության խնդիր: Այսօր մեր հետ համագործակցում են Հայաստանում գործող առաջատար բժշկական կենտրոնները և լաբորատորիաները:",
      
  },
  categories: { uk: "Categories", am: "Բաժիններ" },
  subCategories: { uk: "Subcategories", am: "Ենթաբաժիններ" },
  partners: { uk: "Our partners", am: "Գործընկերներ" },
  newPr: { uk: "New products", am: "Նոր ապրանքներ" },
  latestNews: { uk: "Latest News", am: "Նորություններ" },
};

export default translations;
