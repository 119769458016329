import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import s from "./Navbar.module.css";
import translations from "../../../translate";
import Modal from "../Dialog";
import { IconButton } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import API from "../../../API";

export default function Navbar() {
    


    if (!localStorage.lang) {
        localStorage.lang = "uk";
    }
  const headerNames = [
    { name: translations.home[localStorage.lang], to: "/" },
    { name: translations.products[localStorage.lang], to: "/product" },
    { name: translations.news[localStorage.lang], to: "/news" },
    { name: translations.services[localStorage.lang], to: "/services" },
    { name: translations.contact[localStorage.lang], to: "/contact" },
  ];

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const headBtn = headerNames.map((btn) => (
    <div className={localStorage.lang === 'uk'?s.forB:s.amLang} key={JSON.stringify(btn) + Math.random() * 1500}>
      <NavLink exact to={btn.to} activeClassName={s.selected}>
        {btn.name}
      </NavLink>
    </div>
  ));
  
  return (
    <nav className={s.nav}>
      {headBtn}
      
      <div className={s.burger}>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MenuIcon style={{color:'white'}}/>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          headerNames.map((item,i)=>{
            return <MenuItem key={i} onClick={handleClose} component={Link} to={item.to}>{item.name}</MenuItem>
          })
        }
      </Menu>
    </div>
      <Modal />
    </nav>
  );
}
