import React, { useState } from "react";
import API from "../../API";
import s from "./Product.module.css";
import am from "../../imgs/am.jpg";
import uk from "../../imgs/uk.jpg";
import AddIcon from "@material-ui/icons/Add";
import { DialogActions, IconButton } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import PhotoIcon from "@material-ui/icons/Photo";
const useStyles = makeStyles((theme) => ({
  auth: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  authInput: {
    margin: 10,
    padding: 10,
  },
  setLangs: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  adminAdd: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function Admin({ type }) {
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({
    titleAm: "",
    titleUk: "",
    image: null,
    descriptionUk: "",
    descriptionAm: "",
    keyFeatures:""
  });
  // const [errors, setErrors] = useState(null);
  const classes = useStyles();
  const handleClose = () => {
    setOpen(!open);
  };
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.token}`,
  };
  const add = () => {
    const newData = new FormData();
    newData.append("image", info.image);
    newData.append("titleAm", info.titleAm);
    newData.append("titleUk", info.titleUk);
    newData.append("descriptionUk", info.descriptionUk);
    newData.append("descriptionAm", info.descriptionAm);
    newData.append("type", type);

    if(type==='service'){
      newData.append('keyFeatures',info.keyFeatures)
    }

    
    API.post(`opt-categories/create`, newData, { headers: headers }).then((r) => {
        window.location.reload();
      }).catch(()=>{
        localStorage.removeItem('token')
        window.location.reload()
      })
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const lineBreak = (keyBoard, position, key) =>{
    String.prototype.splice = function(idx, rem, str) {
      return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
  };
    if(keyBoard==='Enter'){
      const cursorPosition = position.target.selectionStart
      let res = position.target.value.splice(cursorPosition,0,'/n')
      setInfo({...info, [key]: res})
    }
  }
console.log(info.descriptionAm);
  return (
    <>
      <div className={s.forLang}>
        <IconButton onClick={handleClickOpen}>
          <AddIcon color="primary" />
        </IconButton>
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">Add {type}</DialogTitle>
        <DialogContent>
          <div className={classes.adminAdd}>
            <label htmlFor="image">
              <PhotoIcon />
              <input
                type="file"
                style={{ display: "none" }}
                id="image"
                onChange={(e) => setInfo({ ...info, image: e.target.files[0] })}
              />
            </label>
          </div>
          <br />
          <div className={classes.adminAdd}>
            <div className={classes.setLangs}>
              <img alt="AM" src={am} width="50" />
              <br />
              
              <input
                placeholder="title"
                onChange={(e) => setInfo({ ...info, titleAm: e.target.value })}
              />
              <br />
              <textarea
                onKeyPress={(e)=>lineBreak(e.key,e, 'descriptionAm')}
                onChange={(e) =>
                  setInfo({ ...info, descriptionAm: e.target.value })
                }
                rows="5"
              ></textarea>
            </div>
            {type==='service'?<input style={{height:20}} placeholder='key features' onChange={(e)=>setInfo({...info,keyFeatures:e.target.value})}/>:null}
            <div className={classes.setLangs}>
              <img alt="UK" src={uk} width="50" />
              <br />
              <input
                placeholder="title"
                onChange={(e) => setInfo({ ...info, titleUk: e.target.value })}
              />
              <br />
              <textarea
              onKeyPress={(e)=>lineBreak(e.key,e, 'descriptionUk')}
                onChange={(e) =>
                  setInfo({ ...info, descriptionUk: e.target.value })
                }
                rows="5"
              ></textarea>
            </div>
          </div>
          <DialogActions>
            <Button onClick={add}>Add</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
