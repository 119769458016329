import React from "react";
import Header from "../../../Components/Header/Header";
import HelperForNews from "./helperForNews/HelperForNews";

export default function OneNewsPage() {
  return (
    <>
      <Header />
      <HelperForNews />
    </>
  );
}
