import React from 'react'
import Header from '../../Components/Header/Header'
import Main from '../../Components/main/Main'
import t from '../../translate'

export default function Home() {
    return (
        <>
           <Header />
           <Main />
           <div style={{marginTop:100,textAlign:'center',display:'flex', flexDirection:'column', alignItems:'center'}}>
               <h2 style={{textTransform:'uppercase'}}>{t.about[localStorage.lang]}</h2>
               <p style={{maxWidth:900}}>{t.aboutText[localStorage.lang]}</p>
           
           </div>
           
        </>
    )
}
