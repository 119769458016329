import React, { useEffect, useState } from "react";
import s from "./HelperForNews.module.css";
import moment from "moment";
import { BASE_URL } from "../../../../url.json";
import DescriptionIcon from "@material-ui/icons/Description";

export default function HelperForNews() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const lsd = JSON.parse(localStorage.dinamicInfo);
    setData(lsd);
  }, []);

  return (
    <div className={s.newsOne}>
      <div>
        <DescriptionIcon color="primary" style={{ fontSize: "36px" }} />
        <b>{moment(data?.date).format("L")}</b>
        <h1>{data?.title[localStorage.lang]}</h1>
      </div>
      <img alt="news" src={`${BASE_URL}${data?.image}`}/>
      <p>{data?.description[localStorage.lang]}</p>
    </div>
  );
}
