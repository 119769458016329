import React from "react";
import Header from "../../../Components/Header/Header";
import HelperForServices from "./helperForServices/HelperForServices";

export default function OneServicePage() {
  return (
    <>
      <Header />
      <HelperForServices />
    </>
  );
}
