import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../../url.json";
import s from "./HelperForServices.module.css";
import moment from "moment";
import DescriptionIcon from "@material-ui/icons/Description";

export default function HelperForServices() {
  const [data, setData] = useState(null);
  const [keys, setKeys] = useState(null);

  useEffect(() => {
    const lsd = JSON.parse(localStorage.dinamicInfo);
    const keyFeatures = !lsd.keyFeatures?null: lsd.keyFeatures.split(',')
    setKeys(keyFeatures)
    setData(lsd);
  }, []);
   return (
    <div>
      <div className={s.desc}>
        <DescriptionIcon color="primary" style={{ fontSize: "36px" }} />
        <b>{moment(data?.date).format("L")}</b>
      </div>
      <div className={s.helper}>
        <div className={s.forImg}>
          <img alt="news" src={`${BASE_URL}${data?.image}`} />
        </div>
        <div className={s.textPart}>
          <h1>{data?.title[localStorage.lang]}</h1>
          <p>{data?.description[localStorage.lang]}</p>
          <br />
          <h4>Key features</h4>
          <ul>
            {
              keys && keys.map((item,i)=>{
                return <li key={item+i}>{item}</li>
              })
            }
          </ul>
        </div>
      </div>
    </div>
  );
}
