import React, { useEffect, useState } from "react";
import s from "./Branch.module.css";
import API from "../../../API";
import Modal from "../Dialog";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../url.json";
import { IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Edit from '../../../AdminArea/Edit'

export default function Branch() {
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
    API.get("opt-product/product-category")
      .then((r) => {
        const result = [];
        r.data.map((item) => {
          const DATA = {};
          DATA["id"] = item._id;
          DATA["title"] = { am: item.titleAm, uk: item.titleUk };
          DATA["image"] = item.image;
          result.push(DATA);
        });
        setData(result);
      })
      .then(() => setLoad(false));
  }, []);

  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.token}`,
  };

  const deleteProd = (id) => {
     API.delete(`opt-product/product-category/${id}`, { headers: headers })
      .then((r) => {
        window.location.reload();
      })
      .catch((err) => {
        localStorage.removeItem("token");
        window.location.reload();
      });
  };



  const branch =
    data &&
    data.map((b, i) => (
      <div key={b.id + i} className={s.oneBranch}>
        {localStorage.token ? (
          <div style={{display:'flex',position:'absolute'}}>
            <IconButton onClick={() => deleteProd(b.id)}>
              <HighlightOffIcon color="error" />
            </IconButton>
            <Edit item={b} url={'product-category'}/>
          </div>
        ) : null}
        <Link to={`/subproduct/${b.id}`} className={s.branchTitle}>
        <img
          className={s.branchImg}
          alt="branch"
          src={`${BASE_URL}${b.image}`}
        />
          {b.title[localStorage.lang]}
        </Link>
      </div>
    ));
  return (
    <>
      {localStorage.getItem("token") ? (
        <Modal type={"product-category"} product={false} />
      ) : null}
      {load ? (
        <>Loading...</>
      ) : (
        <div className={s.branchPage}>
          <div className={s.branches}>{load ? <>loading</> : branch}</div>
        </div>
      )}
    </>
  );
}
