import React, { useState, useEffect } from 'react'
import Header from '../../Components/Header/Header'
import s from '../News/oneNews/OneNews.module.css'
import DescriptionIcon from "@material-ui/icons/Description";
import { BASE_URL } from "../../url.json";
import moment from "moment";
import API from '../../API'
export default function LatestNews() {
    const [data, setData] = useState(null)
    const [load, setLoad] = useState(false)
    useEffect(() => {
      setLoad(true)
        API.get("opt-categories/news").then((r) => {
          setData(r.data);
        }).then(()=>{
          setLoad(false)
        })
      }, []);
    return (
        <div>
      <Header />
      <div>
        <div className={s.title}>
          <DescriptionIcon color="primary" style={{ fontSize: "36px" }} />
          <h1>News</h1>
        </div>
     
      </div>
      <div className={s.allNews}>
        {
            load?<>loading...</>:data && data.slice(0,3).map(i=>{
                return(
                    <div key={i.id} className={s.OneNews}>
                        <img alt="OneNews" src={`${BASE_URL}${i.image}`} />
                        <h4>{moment(i.date).format('L')}</h4>
                        <h2>{i.title[localStorage.getItem("lang")]}</h2>
                        <p>{i.description[localStorage.getItem("lang")]}</p>
                    </div>
                )
            })
        }
        </div>
    </div>
    )
}
