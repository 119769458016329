import React, {useEffect, useState} from 'react'
import s from '../Product/subBranch/SubBranch.module.css'
import API from "../../API";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../url.json";
import Header from '../../Components/Header/Header';
export default function NewProducts() {
    const [data, setData] = useState(null);
    const [load, setLoad] = useState(false)
  useEffect(() => {
    setLoad(true)
    API.get("opt-product/subcategories").then((r) => {
      setData(r.data);
    }).then(()=>{
      setLoad(false)
    })
  }, []);

  const branch =
    data &&
    data.map((b) => (
      <div className={s.oneSubBranch} key={b.id}>
        <Link to={`/OneProduct/${b.id}`} className={s.subBranchTitle}>
        <img
          className={s.subBranchImg}
          alt="branch"
          src={`${BASE_URL}${b.image}`}
        />
          {b.title[localStorage.lang]}
        </Link>
      </div>
    ));
  return (
    <>
    <Header />
    <div className={s.subBranchPage}>
    {
      load?<>loading</>:<div className={s.subBranches}>{branch}</div>
    }
    </div>
    
      
    </>
  );
}
