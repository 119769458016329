import React, { useState } from "react";
import API from "../API";
import s from "../Components/AdminArea/Product.module.css";
import am from "../imgs/am.jpg";
import uk from "../imgs/uk.jpg";
import EditIcon from "@material-ui/icons/Edit";
import { DialogActions, IconButton } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import PhotoIcon from "@material-ui/icons/Photo";
const useStyles = makeStyles((theme) => ({
  auth: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  authInput: {
    margin: 10,
    padding: 10,
  },
  setLangs: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  adminAdd: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function EditNewsServices({ item, type }) {
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({
    titleAm: item.title.am,
    titleUk: item.title.uk,
    image: null,
    descriptionUk: item.description.uk,
    descriptionAm: item.description.am,
    keyFeatures: item.keyFeatures
  });

  const classes = useStyles();
  const handleClose = () => {
    setOpen(!open);
  };
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.token}`,
  };
  const add = () => {
    const newData = new FormData();
    if(info.image){
        newData.append("image", info.image);
    }
    
    newData.append("titleAm", info.titleAm);
    newData.append("titleUk", info.titleUk);
    newData.append("descriptionUk", info.descriptionUk);
    newData.append("descriptionAm", info.descriptionAm);
    newData.append("keyFeatures", info.keyFeatures)
    API.post(`opt-categories/update/${item.id}`, newData, { headers: headers }).then((r) => {
        window.location.reload();
      }).catch(err=>{
        localStorage.removeItem('token')
        window.location.reload();
      })
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div className={s.forLang}>
        <IconButton onClick={handleClickOpen}>
        <EditIcon color="primary" />
        </IconButton>
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">Edit</DialogTitle>
        <DialogContent>
          <div className={classes.adminAdd}>
            <label htmlFor="image">
              <PhotoIcon />
              <input
                type="file"
                style={{ display: "none" }}
                id="image"
                onChange={(e) => setInfo({ ...info, image: e.target.files[0] })}
              />
            </label>
          </div>
          <br />
          <div className={classes.adminAdd}>
            <div className={classes.setLangs}>
              <img alt="AM" src={am} width="50" />
              <br />
              <input
                value={info.titleAm}
                placeholder="title"
                onChange={(e) => setInfo({ ...info, titleAm: e.target.value })}
              />
              <br />
              <textarea
                value={info.descriptionAm}
                onChange={(e) =>
                  setInfo({ ...info, descriptionAm: e.target.value })
                }
                rows="5"
              ></textarea>
              
            </div>
            {type==='service'?<input style={{height:20}} placeholder='key features' value={info.keyFeatures} onChange={(e)=>setInfo({...info,keyFeatures:e.target.value})}/>:null}
            <div className={classes.setLangs}>
              <img alt="UK" src={uk} width="50" />
              <br />
              <input
                value={info.titleUk}
                placeholder="title"
                onChange={(e) => setInfo({ ...info, titleUk: e.target.value })}
              />
              <br />
              <textarea
              value={info.descriptionUk}
                onChange={(e) =>
                  setInfo({ ...info, descriptionUk: e.target.value })
                }
                rows="5"
              ></textarea>
            </div>
          </div>
          <DialogActions>
            <Button onClick={add}>Edit</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
