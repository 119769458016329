import React from "react";
import s from "./Main.module.css";
import Nav from "./nav/Nav";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import sl1 from "../../imgs/sl1.png";
import sl2 from "../../imgs/sl2.jpg";

const images = [{ src: sl1 }, { src: sl2 }];
export default function Main() {
  return (
    <>
      <div className={s.main}>
        <Carousel
          isAutoPlaying={true}
          autoPlayInterval={3000}
          images={images}
        />
        <Nav />
      </div>
    </>
  );
}
