import React, { useState } from "react";
import API from "../../API";
import s from "./Product.module.css";
import am from "../../imgs/am.jpg";
import uk from "../../imgs/uk.jpg";
import AddIcon from "@material-ui/icons/Add";
import { DialogActions, IconButton } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import PhotoIcon from "@material-ui/icons/Photo";

const useStyles = makeStyles((theme) => ({
  auth: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  authInput: {
    margin: 10,
    padding: 10,
  },
  setLangs: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  adminAdd: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function Modal({ type }) {
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({ titleAm: "", titleUk: "", image: null });
  const [errors, setErrors] = useState(null);

  const classes = useStyles();

  const handleClose = () => {
    setOpen(!open);
  };

  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.token}`,
  };

  const add = () => {
    const newData = new FormData();

    newData.append("image", info.image);
    newData.append("titleAm", info.titleAm);
    newData.append("titleUk", info.titleUk);

    if (!info.image) {
      setErrors("add an image");
    } else {
      API.post(`opt-product/${type}`, newData, { headers: headers }).then(
        (r) => {
          window.location.reload();
        }
      );
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div className={s.forLang}>
        <IconButton onClick={handleClickOpen}>
          <AddIcon color="primary" />
        </IconButton>
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">Add branch</DialogTitle>
        <DialogContent>
          <div className={classes.adminAdd}>
            <label htmlFor="image">
              <PhotoIcon />
              <input
                type="file"
                style={{ display: "none" }}
                id="image"
                onChange={(e) => setInfo({ ...info, image: e.target.files[0] })}
              />
            </label>
          </div>
          <br />
          <div className={classes.adminAdd}>
            {/* {errors && <Typography color="error">{errors}</Typography>} */}

            <div className={classes.setLangs}>
              <img alt="img" src={am} width="50" />
              <br />
              <input
                placeholder="title"
                onChange={(e) => setInfo({ ...info, titleAm: e.target.value })}
              />
            </div>
            <div className={classes.setLangs}>
              <img alt="img" src={uk} width="50" />
              <br />
              <input
                placeholder="title"
                onChange={(e) => setInfo({ ...info, titleUk: e.target.value })}
              />
            </div>
          </div>
          <DialogActions>
            <Button onClick={add}>Add</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
