import React, { useEffect, useState } from "react";
import s from "./Main.module.css";
import AdminProduct from "../../../../Components/AdminArea/AdminProduct";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import API from "../../../../API";
import { useParams } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditProduct from "../../../../AdminArea/EditProduct";
import Pagination from '@material-ui/lab/Pagination';

export default function Main({data}) {
  const [currentPage, setCurrentPage]= useState(1)
  const [itemsPerPage]=useState(6)
  const pageNumbers=[]

  for (let i=1;i<=Math.ceil(data.length/itemsPerPage);i++){
    pageNumbers.push(i)
  }

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage

  const currentItems = data.slice(indexOfFirstItem,indexOfLastItem)
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.token}`,
  };

  const remove = (id) => {
    API.delete(`opt-product/product/${id}`, { headers: headers })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        localStorage.removeItem("token");
        window.location.reload();
      });
  };

  return (
    <>
    <main className={s.content}>
    
      {localStorage.getItem("token") ? <AdminProduct /> : null}
      {
        currentItems.map((item) => {
          return (
            
            <div key={item.id} className={s.product}>
              <img alt="product" src={`https://api.optolas.am/${item.image}`} />
              <div className={s.info}>
                <h2>
                  {item.code}
                  {localStorage.token ? (
                    <>
                    <IconButton onClick={() => remove(item.id)}>
                      <HighlightOffIcon color="error" />
                    </IconButton>
                    <EditProduct item={item}/>
                    </>
                  ) : null}
                  
                </h2>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      {item.description[localStorage.lang].split('/n').map(text=><p>{text}</p>)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {!item.file?null:<a target="_blank" href={`https://api.optolas.am/${item.file}`} > EN </a>}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          );
        })
}
<div style={{margin:30}}>
<Pagination className={s.pages} count={pageNumbers.length}
       variant="outlined" 
       color={'primary'}
       page={currentPage}
       onChange={handleChange}
       />
</div>

    </main>
    
    </>
  );
}
