import React,{useEffect, useState} from "react";
import Header from "../../../Components/Header/Header";
import Main from "./main/Main";
import s from "./OneProduct.module.css";
import SideSubBranch from "./sideSubBranch/SideSubBranch";
import API from "../../../API";
import { useParams } from "react-router-dom";

export default function OneProduct(){
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [search, setSearch] = useState('')
  const { id } = useParams();

  useEffect(() => {
    API.get(`opt-product/products/${id}`)
      .then((r) => {
        setData(r.data.filter(item=>item.code.includes(search)))
        setLoad(false);
      })
  }, [search]);

  return (
    <>
      <Header />      
      <h3 className={s.title}>Product</h3>
      <div className={s.inputArea}>
        <input onChange={(e)=>setSearch(e.target.value)} placeholder="Search"/>
      </div>
      {
        !load&&<div className={s.content}>
        <SideSubBranch/>
        {data&&<Main data={data}/>}
        </div>
      }
    </>
  );
}
