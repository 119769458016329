import React, { useEffect, useState } from "react";
import s from "./Service.module.css";
import { BASE_URL } from "../../../url.json";
import API from "../../../API";
import { IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import moment from "moment";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import EditNewsServices from "../../../AdminArea/EditNewsServices";

export default function Service() {
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(false);
  let history = useHistory();
  const headers = {
    Authorization: `Bearer ${localStorage.token}`,
  };

  useEffect(() => {
    setLoad(true);
    API.get("opt-categories/service")
      .then((r) => setData(r.data))
      .then(() => {
        setLoad(false);
      });
  }, []);

  const handleDelete = (id) => {
    API.delete(`opt-categories/${id}`, { headers: headers }).then((r) =>
      window.location.reload()
    );
  };

  const goToService = (item) => {
    localStorage.dinamicInfo = JSON.stringify(item);
    history.push("oneservicepage");
  };

   const service =
    data &&
    data.map((i) => (
      <div key={i.id}>

{localStorage.getItem("token") && (
            <div style={{display:'flex'}}>
              <IconButton onClick={() => handleDelete(i.id)}>
                <HighlightOffIcon color="error" />
              </IconButton>
              <EditNewsServices item={i} type={'service'}/>
            </div>
          )}
      <div className={s.service} onClick={() => goToService(i)}>
        <div>
          <img alt="service" src={`${BASE_URL}${i.image}`} />
        </div>
        <div className={s.texts}>
          <h4>{moment(i.date).format("L")}</h4>
          <h2>{i.title[localStorage.getItem("lang")]}</h2>
          <p>
            {i.description[localStorage.getItem("lang")].length > 350
              ? i.description[localStorage.getItem("lang")].slice(0, 350) +
                "..."
              : i.description[localStorage.getItem("lang")]}
          </p>
          
        </div>
      </div>
      </div>
    ));
  return <div className={s.allServices}>{load ? <>loading</> : service}</div>;
}
