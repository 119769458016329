import React from "react";
import Header from "../../Components/Header/Header";
import t from '../../translate'
import img1 from "../../imgs/partners/1i.png";
import img2 from "../../imgs/partners/2i.png";
import img3 from "../../imgs/partners/3i.png";
import img4 from "../../imgs/partners/4i.jpg";
import img5 from "../../imgs/partners/5i.png";
import img6 from "../../imgs/partners/6i.png";
import img7 from "../../imgs/partners/7i.png";
import img8 from "../../imgs/partners/8i.png";
import img9 from "../../imgs/partners/9i.png";
import img10 from "../../imgs/partners/10i.png";
import img11 from "../../imgs/partners/11i.png";
import img12 from "../../imgs/partners/12i.png";
import img13 from "../../imgs/partners/13i.png";
import img14 from "../../imgs/partners/14i.png";
import img15 from "../../imgs/partners/15i.png";
import img16 from "../../imgs/partners/16i.png";
import img17 from "../../imgs/partners/17i.jpg";
import "./style.css";

export default function Partners() {
  const images = [
    { src: img1 , title:'Արյան բանկ'},
    { src: img2 , title:'<<Արևիկ>> հանրապետական բժշկական կենտրոն'},
    { src: img3 , title:''},
    { src: img4 , title:''},
    { src: img5 , title:''},
    { src: img6 , title:''},
    { src: img7 , title:''},
    { src: img8 , title:''},
    { src: img9 , title:''},
    { src: img10, title:'' },
    { src: img11, title:''},
    { src: img12, title:'' },
    { src: img13, title:'' },
    { src: img14, title:'' },
    { src: img15, title:'' },
    { src: img16, title:'' },
    { src: img17, title:'' },
  ];
  return (
    <>
      <Header />
      <div>
        <div className='title-block'>
          <h1 className='partners-title'>{t.partners[localStorage.lang]}</h1>
          <span></span>
        </div>
        
        <div className='grid-container'>
            {
              images.map((item,i)=>{
                return(
                  <div key={i} className='partner-card'>
                    <img src={item.src}/>
                    {/* <p>{item.title}</p> */}
                  </div>
                )
              })
            }
        </div>
      </div>

    </>
  );
}
