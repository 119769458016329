import React from 'react'
import Header from '../../Components/Header/Header'
import Branch from './Branch/Branch'


export default function Product() {
    return (
        <div>
            <Header />
            <Branch />
        </div>
    )
}
