import React, { useEffect, useRef, useState } from "react";
import API from "../../API";
import s from "./Nav/Navbar.module.css";
import am from "../../imgs/am.jpg";
import uk from "../../imgs/uk.jpg";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { IconButton, List, ListItem } from "@material-ui/core";
import style from './Header.module.css'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  auth: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  authInput: {
    margin: 10,
    padding: 10,
  },
}));

export default function Modal() {
  const [open, setOpen] = useState(false);
  const [login, setLogin] = useState({
    login: "",
    password: "",
  });
  const [errors, setErrors] = useState(null);
  const [text,setText] = useState('')
    const [data,setData] = useState(null)
    const [isMenuOpen, setIsMenuOpen] = useState(data?true:false)
    const ref = useRef()
    useEffect(()=>{
      if(text.length>1){
        API.get(`opt-product/search?text=${text.toLowerCase()}`)
        .then(r=>{
          setData(r.data)
        })
      }
      if(!text){
        setData(null)
      }
    },[text])


    useEffect(()=>{
      const checkIfClickedOutside = e => {
        if (data && ref.current && !ref.current.contains(e.target)) {
          setData(null)
          setText('')
        }
      }
  
      document.addEventListener("mousedown", checkIfClickedOutside)
  
      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", checkIfClickedOutside)
      }
    },[data && data.length])

  const classes = useStyles();
  const handleClose = () => {
    setOpen(!open);
  };
  const onLogedin = () => {
    API.post("auth/login", login)
      .then((r) => {
        localStorage.setItem("token", r.data.token);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.data.status === 401) {
          localStorage.removeItem("token");
          setErrors(err.response.data.error);
        } else if (err.response.data.status === 404) {
          localStorage.removeItem("token");
          setErrors(err.response.data.error);
        } else {
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const logout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  const changeLang = (lang) => {
    localStorage.lang = lang;
    window.location.reload();
  };

  return (
    <>
    <div>
      <div className={s.forLang}>
        <IconButton onClick={() => changeLang("am")}>
          <img className={s.flag} alt="am" src={am} />
        </IconButton>
        <IconButton onClick={() => changeLang("uk")}>
          <img className={s.flag} alt="uk" src={uk} />
        </IconButton>
        {localStorage.token ? (
          <IconButton onClick={logout}>
            <ExitToAppIcon color={"error"} />
          </IconButton>
        ) : (
          <IconButton onClick={handleClickOpen}>
            <ExitToAppIcon color={"primary"} />
          </IconButton>
        )}
      </div>
      <div className={style.drpoDown}>
      <input placeholder='Search ...' onChange={(e)=>setText(e.target.value)} value={text}/>
          <div ref={ref}>
            <List>
              {data && data.map(item=><ListItem key={item.route+Math.random()*255} divider component={Link} to={item.route} style={{color:'black'}}>{item.title[localStorage.lang]}</ListItem>)}
            </List>
          </div>
      </div>
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">Admin Log in</DialogTitle>
        <DialogContent>
          <div className={classes.auth}>
            {errors && <Typography color="error">{errors}</Typography>}
            <input
              value={login.login}
              onChange={(e) => setLogin({ ...login, login: e.target.value })}
              className={classes.authInput}
              placeholder="login"
            />
            <input
              value={login.password}
              onChange={(e) => setLogin({ ...login, password: e.target.value })}
              className={classes.authInput}
              placeholder="password"
            />
            <Button onClick={onLogedin}>Log in</Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}