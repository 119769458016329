import React, { useState } from 'react'
import API from "../API";
import s from "../HightComponents/Product/Branch/Branch.module.css";
import am from "../imgs/am.jpg";
import uk from "../imgs/uk.jpg";
import { DialogActions, IconButton } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PhotoIcon from "@material-ui/icons/Photo";

const useStyles = makeStyles((theme) => ({
    auth: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    authInput: {
      margin: 10,
      padding: 10,
    },
    setLangs: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    adminAdd: {
      display: "flex",
      justifyContent: "center",
    },
  }));
  
export default function Edit({item, url}) {
    const [open, setOpen] = useState(false);
    const [info, setInfo] = useState({ titleAm: item.title.am, titleUk: item.title.uk, image: null });
    const classes = useStyles();
     const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.token}`,
      };

    const editBranch=()=>{
            const data = new FormData()
            if(info.image){
                data.append('image', info.image)    
            }

            data.append('titleAm', info.titleAm)
            data.append('titleUk', info.titleUk)
            
        API.patch(`opt-product/${url}/${item.id}`,data, { headers: headers })
        .then((r) => {
          window.location.reload();
        })
        .catch((err) => {
          localStorage.removeItem("token");
          window.location.reload();
        });
      }

      const handleClose = () => {
        setOpen(!open);
      };
    
    return (
        <>
        <div className={s.forLang}>
            <IconButton onClick={()=>setOpen(true)}>
              <EditIcon color="primary" />
            </IconButton>
        </div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
        >
          <DialogTitle id="simple-dialog-title">Edit branch</DialogTitle>
          <DialogContent>
            <div className={classes.adminAdd}>
              <label htmlFor="image">
                <PhotoIcon />
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="image"
                  onChange={(e) => setInfo({ ...info, image: e.target.files[0] })}
                />
              </label>
            </div>
            <br />
            <div className={classes.adminAdd}>
              {/* {errors && <Typography color="error">{errors}</Typography>} */}
  
              <div className={classes.setLangs}>
                <img alt="img" src={am} width="50" />
                <br />
                <input
                  placeholder="title"
                  onChange={(e) => setInfo({ ...info, titleAm: e.target.value })}
                  value={info.titleAm}
                />
              </div>
              <div className={classes.setLangs}>
                <img alt="img" src={uk} width="50" />
                <br />
                <input
                  placeholder="title"
                  onChange={(e) => setInfo({ ...info, titleUk: e.target.value })}
                  value={info.titleUk}
                />
              </div>
            </div>
            <DialogActions>
              <Button onClick={editBranch}>Edit</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </>
  
    )
}
