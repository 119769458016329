import React, { useEffect, useState } from "react";
import Header from "../../../Components/Header/Header";
import s from "./SubBranch.module.css";
import Modal from "../Dialog";
import { Link, useParams } from "react-router-dom";
import API from "../../../API";
import { BASE_URL } from "../../../url.json";
import { IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Edit";
import Edit from "../../../AdminArea/Edit";

export default function SubBranch() {
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(false);
  const { id } = useParams();
  localStorage.setItem("id", id);

  useEffect(() => {
    setLoad(true);
    API.get(`opt-product/subCategories/${id}`)
      .then((r) => {
        setData(r.data);
      })
      .then(() => {
        setLoad(false);
      });
  }, [id]);

  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.token}`,
  };

  const deleteProd = (id) => {
    API.delete(`opt-product/subcategory/${id}`, { headers: headers }).then(
      (r) => {
        window.location.reload();
      }
    );
  };
 
  const subBranch =
    data &&
    data.map((b) => (
      <div className={s.oneSubBranch} key={b.id}>
        {localStorage.getItem("token") && (
          <div style={{display:'flex',position:'absolute'}}>
            <IconButton onClick={() => deleteProd(b.id)}>
              <HighlightOffIcon color="error" />
            </IconButton>
            <Edit item={b} url={'update-subcategory'}/>
          </div>
        )}
        <Link to={`/OneProduct/${b.id}`} className={s.subBranchTitle}>
        <img
          className={s.subBranchImg}
          alt="branch"
          src={`${BASE_URL}${b.image}`}
        />
          {b.title[localStorage.lang]}
        </Link>
      </div>
    ));
  return (
    <>
      <Header />
      {localStorage.getItem("token") ? (
        <Modal type={`create-subcategory/${id}`} product={true} />
      ) : (
        <></>
      )}
      <div className={s.subBranchPage}>
        <div className={s.subBranches}>{load ? <>loading</> : subBranch}</div>
      </div>
    </>
  );
}
