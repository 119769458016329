import React, { useState } from "react";
import API from "../API";
import s from "../Components/AdminArea/Product.module.css";
import am from "../imgs/am.jpg";
import uk from "../imgs/uk.jpg";
import { DialogActions, IconButton } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PhotoIcon from "@material-ui/icons/Photo";
import { useParams } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
const useStyles = makeStyles((theme) => ({
  auth: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  authInput: {
    margin: 10,
    padding: 10,
  },
  setLangs: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  adminAdd: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function EditProduct({item}) {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [info, setInfo] = useState({
    code: item.code,
    image: null,
    descriptionUk: item.description.uk,
    descriptionAm:  item.description.am,
    file: null,
  });
  // const [errors, setErrors] = useState(null);
  const classes = useStyles();
  const handleClose = () => {
    setOpen(!open);
  };

  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.token}`,
  };

  const add = () => {
    const newData = new FormData();
    
    if(info.file){
      newData.append("file", info.file);
    }

    if(info.image){
        newData.append("image", info.image);
    }

    newData.append("code", info.code);
    newData.append("descriptionUk", info.descriptionUk);
    newData.append("descriptionAm", info.descriptionAm);
    newData.append("subCategory", id);


    API.post(`opt-product/update-product/${item.id}`, newData, {
      headers: headers,
    }).then((r) => {
      window.location.reload();
    }).catch(err=>{
      localStorage.removeItem('token')
      window.location.reload();
    })

  };
 
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div className={s.forLang}>
        <IconButton onClick={handleClickOpen}>
          <EditIcon color="primary" />
        </IconButton>
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">Edit Product</DialogTitle>
        <DialogContent>
          <input
            value={info.code}
            style={{ width: "100%" }}
            placeholder="code"
            onChange={(e) => setInfo({ ...info, code: e.target.value })}
          />
          <br />
          <div className={classes.adminAdd}>
            <label htmlFor="image">
              <PhotoIcon />
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="image"
                onChange={(e) => setInfo({ ...info, image: e.target.files[0] })}
              />
            </label>
            <label htmlFor="file">
              <PictureAsPdfIcon />
              <input
                type="file"
                accept="application/pdf"
                style={{ display: "none" }}
                id="file"
                onChange={(e) => setInfo({ ...info, file: e.target.files[0] })}
              />
            </label>
          </div>
          <br />

          <div className={classes.adminAdd}>
            {/* {errors && <Typography color="error">{errors}</Typography>} */}

            <div className={classes.setLangs}>
              <img alt="AM" src={am} width="50" />
              <br />
              <textarea
                value={info.descriptionAm}
                onChange={(e) =>
                  setInfo({ ...info, descriptionAm: e.target.value })
                }
                rows="5"
              ></textarea>
            </div>
            <div className={classes.setLangs}>
              <img alt="UK" src={uk} width="50" />
              <br />
              <textarea
               value={info.descriptionUk}
                onChange={(e) =>
                  setInfo({ ...info, descriptionUk: e.target.value })
                }
                rows="5"
              ></textarea>
            </div>
          </div>
          <DialogActions>
            <Button onClick={add}>Edit</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
