import React from "react";
import s from "./LogoPage.module.css";
import logo from "../../../imgs/logo.png";

export default function LogoPage() {
  return (
    <div className={s.logo}>
      <img alt="logo" src={logo} />
    </div>
  );
}
