import React, { useState } from "react";
import style from "./CompMain.module.css";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import Button from '@material-ui/core/Button';

export default function CompMain() {
  const [inps,setInps] = useState({
    name:'',
    email:'',
    message:''
  })
  const [errors, setError] = useState(null)
  const sendMessage = () =>{
      if(!inps.name||!inps.email||!inps.message){
          setError({error:'Fill in all inputs'})
          setTimeout(()=>setError(''),5000)
      }
           
  }
  return (
    <div  className={style.all}>
        <header className={style.header}>
            <div>
                 <LocationOnIcon htmlColor={'#000'}/>
                <p style={{color:'#000'}}>Yerevan, Khanjyan 13 ,Armenia</p>
            </div>
            {/* <div>
                 <PhoneIcon htmlColor={'#000'}/>
                <p style={{color:'#000'}}>+374 91 00 03 10</p>
            </div> */}
            <div>
                 <PhoneIcon htmlColor={'#000'} />
                <p style={{color:'#000'}}>+374 10 58 48 73 </p>
            </div>
            {/* <div>
                 <MailIcon htmlColor={'#000'} />
                <p style={{color:'#000'}}>info@leica.am</p>
            </div> */}
        </header>
        <nav className={style.nav} >
        {/* <h3 style={errors && 'error' in errors?{color:'red'}:{color:'green'}}>{errors&&Object.values(errors)}</h3> */}
                <div style={{marginTop:'10px'}}>
                     <input 
                    onChange={(e)=>setInps({...inps,name:e.target.value})}
                    value={inps.name}
                    style={{height:'40px',width:'200px',borderRadius:'15px',padding:10}} 
                    placeholder='Your Name' 
                    />
                </div>
                <div style={{marginTop:'10px'}}>
                     <input 
                    onChange={(e)=>setInps({...inps,email:e.target.value})}
                    value={inps.email}
                    style={{height:'40px',width:'200px',borderRadius:'15px',padding:10}} 
                    placeholder='Your Email' />
                </div> 
                <div style={{marginTop:'10px'}}>
                 
                    <input 
                    onChange={(e)=>setInps({...inps,message:e.target.value})}
                    value={inps.message}
                    style={{height:'100px',width:'200px',borderRadius:'15px',padding:10}} 
                    placeholder='Your Message' />
                </div>   
                <br/>                
                <div style={{marginTop:'10px',marginBottom:'10px'}}>
                    <Button 
                    onClick={sendMessage}
                    variant="contained" 
                    color="primary" 
                    style={{borderRadius:'30px'}} >Send Message</Button>
                </div>
                
            
            
        </nav>
        <div className={style.mapLocation}>
        <div className="mapouter"><div className="gmap_canvas"><iframe width={'100%'} height={400} id="gmap_canvas" src="https://maps.google.com/maps?q=Yerevan%20Khanjyan%2013&t=&z=19&ie=UTF8&iwloc=&output=embed" title='map' frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} /></div>
  </div>
        </div>
    </div>
)
}
