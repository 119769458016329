import React from 'react'
import s from './Header.module.css'
import LogoPage from './logo/LogoPage'
import Navbar from './Nav/Navbar'

export default function Header() {
    return (
        <header className={s.header}>
          
            <LogoPage />
            <Navbar />
            
            
        </header>
    )
}
