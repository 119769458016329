import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import API from "../../../../API";
import s from "./SideSubBranch.module.css";

export default function SideSubBranch() {
  const history = useHistory()
  const [state, setState] = useState(null);
  const [load, setLoad] = useState(false)
  useEffect(() => {
    setLoad(true)
    API.get(`/opt-product/subCategories/${localStorage.getItem("id")}`).then((r) => {
       setState(r.data);
      }
    ).then(()=>{
      setLoad(false)
    })
  }, []);
  return (
    <>
    <ul>
      {state &&
        state.map((m) => (
          <li key={m.id} className={s.list}>
            <a href={`/OneProduct/${m.id}`}>{m.title[localStorage.lang]}</a>
          </li>
        ))}
    </ul>
    <select onChange={e=>{
      window.location.href = e.target.value
      
      }}>
      {
        state &&
        state.map(m=>{
          return(
            <option 
            key={m.id} 
            value={`/OneProduct/${m.id}`}>{m.title[localStorage.lang]}</option>
            // <a href={`/OneProduct/${m.id}`}>{m.title[localStorage.lang]}</a>
          )
        })
      }
    </select>
    </>
  );
}
